/* You can add global styles to this file, and also import other style files */
$roboto-font-path: "~roboto-fontface/fonts";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface.scss";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "styles/icons";
@import "styles/fire-theme";
@import "styles/plyr";
@import "~flexmonster/flexmonster.min.css";
@import '~webdatarocks/webdatarocks.min.css';

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif !important;
  min-height: 100vh;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-theme > .container {
  background: #27272b !important;
}

.fire-spacer {
  flex: 1 1 auto;
}

.mat-toolbar-row,
.mat-toolbar {
  height: 40px !important;
  min-height: 32px !important;
}

.material-icons {
  font-size: 20px !important;
}

.mat-icon {
  height: 20px !important;
  width: 20px !important;
}

mat-sidenav-container {
  height: calc(100vh - 80px);
}

button.mat-menu-item {
  line-height: 20px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon > svg {
  width: 24px;
  height: 24px;
}

/* Scroll*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b7b7b754;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-corner {
  background: #b7b7b700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.example-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(0deg, rgb(0 0 0 / 0%) 33%, rgb(255 0 106) 100%);
  animation: spin 0.8s linear infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background: #ececec;
  border-radius: 50%;
}

.loader-text {
  position: absolute;
  color: #037fc0;
  font-weight: bold;
  font-size: 14px;
  font-family: sans-serif;
}

.loader-text img {
  filter: drop-shadow(-1px 1px 6px rgba(#037fc0, 0.3));
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}